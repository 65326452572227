import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наші послуги в тенісному клубі Tuliner
			</title>
			<meta name={"description"} content={"У тенісному клубі Tuliner ми дбаємо про всі аспекти вашої тенісної подорожі."} />
			<meta property={"og:title"} content={"Наші послуги | Наші послуги в тенісному клубі Tuliner"} />
			<meta property={"og:description"} content={"У тенісному клубі Tuliner ми дбаємо про всі аспекти вашої тенісної подорожі."} />
			<meta property={"og:image"} content={"https://castrestuliner.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://castrestuliner.com/img/3210120.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://castrestuliner.com/img/3210120.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://castrestuliner.com/img/3210120.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://castrestuliner.com/img/3210120.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://castrestuliner.com/img/3210120.png"} />
			<meta name={"msapplication-TileImage"} content={"https://castrestuliner.com/img/3210120.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
						Короткий огляд наших пропозицій
					</Text>
					<Text margin="0px 0px 70px 0px" font="--lead" lg-margin="0px 0px 40px 0px" color="#626970">
						У тенісному клубі Tuliner ми дбаємо про всі аспекти вашої тенісної подорожі. Наш асортимент послуг продумано розроблений таким чином, щоб покращити ваш ігровий досвід, вдосконалити навички та сприяти розвитку активної тенісної спільноти.
					</Text>
					<Image
						src="https://castrestuliner.com/img/5.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Наші послуги включають:
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Професійні тренування: досвідчені тренери пропонують індивідуальні тренування для всіх рівнів, зосереджуючись на техніці, стратегії та фізичній підготовці.
							<br />
							<br />
							- Молодіжні програми розвитку: Спеціально розроблені курси для молодих ентузіастів, спрямовані на ознайомлення з основами тенісу та виховання майбутніх чемпіонів.
							<br />
							<br />
							- Клініки для просунутих гравців: Для досвідчених гравців ці клініки зосереджені на передових техніках, психологічній стійкості та конкурентних стратегіях.
							<br />
							<br />
							- Організація турнірів: Регулярні місцеві та регіональні турніри для перевірки ваших навичок та розвитку змагального духу в дружньому середовищі.
							<br />
							<br />
							- Соціальні вечірки та заходи: Спілкуйтеся з іншими любителями тенісу на наших клубних заходах, вечірках та товариських матчах.
							<br />
							<br />
							- Оренда кортів: Доступ до добре доглянутих критих і відкритих кортів, які члени клубу можуть орендувати для тренувань або звичайних ігор.
							<br />
							<br />
							- Магазин екіпіровки та спорядження: Магазин з повним спектром послуг з найновішим тенісним спорядженням, професійною начинкою ракеток та індивідуальними порадами щодо екіпірування.
							<br />
							<br />
							- Лаунж для членів клубу та зручності: Розслабтеся та поспілкуйтеся у нашій комфортній кімнаті для членів, обладнаній усім необхідним для відпочинку та спілкування.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://castrestuliner.com/img/6.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://castrestuliner.com/img/7.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead"
					lg-text-align="center"
					md-text-align="left"
				>
					Зв'яжіться з нами для отримання додаткової інформації
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Щоб отримати детальну інформацію про кожну послугу або записатися на заняття, будь ласка, звертайтеся на рецепцію нашого клубу. Наша команда завжди готова допомогти вам у виборі ідеальної програми, яка відповідає вашим тенісним прагненням.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});